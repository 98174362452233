<template>
    <div class="container mt-5 mb-5">
        <div class="row">
            <div class="col-12">
                <h1>Loading...</h1>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CheckLink",
        mounted() {
            const {uuid} = this.$route.params;
            this.$axios.get(`projects/${uuid}`).then(res => {
                const {data: {data, data: {sections}}} = res;
                localStorage.removeItem('user');
                localStorage.removeItem('answers-temp');
                localStorage.setItem('test-data', JSON.stringify(data));
                localStorage.setItem('current-section', JSON.stringify(sections[0]));
                this.$router.push({name: 'login'});
            }).catch(e => {
                console.log(e);
                this.$router.push({name: '404'});
            })
        }
    }
</script>

<style scoped>
    .container{
        min-height: 80vh;
    }
</style>